<template>
  <div>
    <BaseMap
      :geojson="JSON.parse(data.geojson)"
      :geostyle="data.geostyle"
      :height="data.height"
      :width="data.width"
    />
  </div>
</template>

<script>
import Base from './Base.vue';
export default {
  extends: Base,
  components: {
    BaseMap: () => import('./Map')
  }
};
</script>

<style lang="scss">
</style>
